import { ref, computed, unref, inject } from "vue";
import api from "@/services/userApi";

export default function useDashboard() {
  const calls = ref([]);
  const messages = ref([]);

  //   Get dashboard calls
  const getDasbboardCalls = async () => {
    try {
      const response = await api.get(`/admin/xpbx/dashboard/calls`);

      if (response?.data?.length) {
        calls.value = response.data;
      }
    } catch (error) {}
  };

  //   Get Dashboard Messages
  const getDasbboardMessages = async () => {
    try {
      const response = await api.get(`/admin/xpbx/dashboard/msgs`);

      if (response?.data?.length) {
        messages.value = response.data;
      }
    } catch (error) {}
  };

  return {
    calls,
    messages,
    getDasbboardCalls,
    getDasbboardMessages,
  };
}
