<template>
  <div class="w-full lg:w-1/2 xl:w-1/4">
    <div
      class="m-5 p-4 rounded-sm border-b-1 border-r-3 border-dark-green bg-white shadow-sm hover:shadow-lg cursor-pointer dashboard-card"
    >
      <div class="">
        <div class="p-3 2xl:p-4">
          <p class="font-bold text-2xl text-center">
            {{ getCardTranslation(data.calltype) }}
          </p>
          <p class="font-bold text-2xl text-center mt-2">
            {{ data.count }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'DashboardCard',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup () {
    const t = inject('t')

    const getCardTranslation = calltype => {
      return t('xpbx.pages.reports.texts.' + calltype)
    }

    return {
      getCardTranslation
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-card {
  border: 1px solid rgba(26, 179, 148, 0.5);
}
</style>
